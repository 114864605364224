import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  FaBuilding, 
  FaThermometerHalf, 
  FaClipboardCheck, 
  FaFileAlt, 
  FaHammer, 
  FaBolt,
  FaWater,
  FaShieldAlt
} from 'react-icons/fa';

const Services = () => {
  const services = [
    {
      id: 'manutenzione',
      icon: <FaBuilding size={48} className="text-warning mb-4" />,
      title: 'Manutenzione Stabili',
      description: 'Offriamo servizi completi di manutenzione ordinaria e straordinaria per edifici residenziali e commerciali. Il nostro team di professionisti è in grado di intervenire tempestivamente per risolvere qualsiasi problema, garantendo efficienza e sicurezza.',
      details: [
        'Manutenzione ordinaria programmata',
        'Interventi di emergenza 24/7',
        'Gestione completa degli impianti',
        'Controlli periodici di sicurezza',
        'Riparazioni e sostituzioni di componenti'
      ],
      ctaText: 'Richiedi un intervento'
    },
    {
      id: 'termici',
      icon: <FaThermometerHalf size={48} className="text-danger mb-4" />,
      title: 'Impianti Termici',
      description: 'Progettiamo, installiamo e manteniamo impianti di riscaldamento e climatizzazione di ogni dimensione. Utilizziamo tecnologie all\'avanguardia per garantire il massimo comfort e l\'efficienza energetica.',
      details: [
        'Installazione di caldaie e sistemi di riscaldamento',
        'Manutenzione e riparazione di impianti esistenti',
        'Conversione a sistemi ad alta efficienza energetica',
        'Installazione di pompe di calore',
        'Sistemi di climatizzazione per ambienti residenziali e commerciali'
      ],
      ctaText: 'Preventivo gratuito'
    },
    {
      id: 'responsabile',
      icon: <FaShieldAlt size={48} className="text-danger mb-4" />,
      title: 'Terzo Responsabile',
      description: 'Assumiamo la responsabilità dell\'esercizio, della manutenzione e dell\'adozione delle misure necessarie al contenimento dei consumi energetici degli impianti termici. Garantiamo il rispetto delle normative vigenti e l\'efficienza degli impianti.',
      details: [
        'Gestione completa degli impianti termici',
        'Controlli periodici e manutenzione programmata',
        'Compilazione del libretto di impianto',
        'Gestione delle pratiche amministrative',
        'Consulenza per l\'ottimizzazione dei consumi'
      ],
      ctaText: 'Richiedi informazioni'
    },
    {
      id: 'pratiche',
      icon: <FaFileAlt size={48} className="text-warning mb-4" />,
      title: 'Pratiche Edili',
      description: 'Ci occupiamo della gestione completa delle pratiche edilizie e amministrative, dalla progettazione alla realizzazione. Il nostro team di professionisti è in grado di seguire ogni fase del processo, garantendo il rispetto delle normative e delle tempistiche.',
      details: [
        'Presentazione di SCIA e CILA',
        'Pratiche di sanatoria',
        'Certificazioni energetiche (APE)',
        'Pratiche catastali',
        'Consulenza tecnica e normativa'
      ],
      ctaText: 'Consulenza tecnica'
    },
    {
      id: 'ristrutturazioni',
      icon: <FaHammer size={48} className="text-danger mb-4" />,
      title: 'Ristrutturazioni',
      description: 'Realizziamo ristrutturazioni complete di edifici residenziali e commerciali, con attenzione ai dettagli e utilizzo di materiali di qualità. Il nostro obiettivo è trasformare gli spazi secondo le esigenze del cliente, garantendo risultati duraturi e di pregio.',
      details: [
        'Ristrutturazioni complete di appartamenti e uffici',
        'Rifacimento di bagni e cucine',
        'Opere murarie e finiture',
        'Adeguamento degli impianti',
        'Consulenza per la scelta dei materiali'
      ],
      ctaText: 'Preventivo gratuito'
    },
    {
      id: 'elettrici',
      icon: <FaBolt size={48} className="text-success mb-4" />,
      title: 'Impianti Elettrici',
      description: 'Progettiamo, installiamo e manteniamo impianti elettrici civili e industriali, con soluzioni innovative e risparmio energetico. Garantiamo la sicurezza e l\'efficienza degli impianti, nel rispetto delle normative vigenti.',
      details: [
        'Installazione di impianti elettrici civili e industriali',
        'Manutenzione e riparazione di impianti esistenti',
        'Installazione di sistemi domotici',
        'Illuminazione a LED e sistemi di risparmio energetico',
        'Verifiche di sicurezza e certificazioni'
      ],
      ctaText: 'Richiedi sopralluogo'
    },
    {
      id: 'idraulici',
      icon: <FaWater size={48} className="text-warning mb-4" />,
      title: 'Impianti Idraulici',
      description: 'Offriamo servizi completi per impianti idraulici, dalla progettazione all\'installazione e manutenzione. Interveniamo tempestivamente per risolvere qualsiasi problema, garantendo efficienza e qualità.',
      details: [
        'Installazione di impianti idraulici',
        'Riparazione di perdite e guasti',
        'Sostituzione di sanitari e rubinetterie',
        'Installazione di sistemi di trattamento dell\'acqua',
        'Manutenzione programmata'
      ],
      ctaText: 'Richiedi assistenza'
    },
    {
      id: 'antincendio',
      icon: <FaShieldAlt size={48} className="text-danger mb-4" />,
      title: 'Sistemi Antincendio',
      description: 'Progettiamo e installiamo sistemi antincendio per edifici residenziali e commerciali, garantendo la sicurezza degli occupanti e il rispetto delle normative vigenti. Offriamo anche servizi di manutenzione e controllo periodico.',
      details: [
        'Installazione di sistemi di rilevazione incendi',
        'Impianti di spegnimento automatico',
        'Porte tagliafuoco e compartimentazioni',
        'Segnaletica di sicurezza',
        'Manutenzione programmata e verifiche periodiche'
      ],
      ctaText: 'Verifica normativa'
    }
  ];

  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - Servizi</title>
        <meta 
          name="description" 
          content="Scopri i servizi offerti da EL.VI. Servizi: manutenzione stabili, impianti termici, terzo responsabile, pratiche edili, ristrutturazioni e molto altro." 
        />
      </Helmet>
      
      {/* Hero Section */}
      <section className="page-hero py-5 mb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">I Nostri Servizi</h1>
                <p className="lead mb-4">
                  Offriamo una gamma completa di servizi per la manutenzione e l'installazione di impianti, con soluzioni personalizzate per ogni esigenza.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Services Section */}
      <section className="services-list py-5">
        <Container>
          {services.map((service, index) => (
            <motion.div
              key={service.id}
              id={service.id}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
            >
              <Card className="border-0 shadow-sm mb-5">
                <Card.Body className="p-5">
                  <Row className="align-items-center">
                    <Col lg={8}>
                      <div className="d-flex align-items-center mb-4">
                        {service.icon}
                        <h2 className="ms-3 mb-0">{service.title}</h2>
                      </div>
                      <p className="lead mb-4">{service.description}</p>
                      
                      <h5 className="mb-3">Cosa offriamo:</h5>
                      <ul className="list-unstyled mb-4">
                        {service.details.map((detail, i) => (
                          <li key={i} className="mb-2">
                            <i className="bi bi-check-circle-fill text-primary me-2"></i>
                            {detail}
                          </li>
                        ))}
                      </ul>
                      
                      <Button as={Link} to="/contact" variant="primary" className="mt-3">
                        {service.ctaText}
                      </Button>
                    </Col>
                    <Col lg={4} className="d-none d-lg-block">
                      <img 
                        src={service.id === 'responsabile' ? "/img/Logo.jpg" : 
                            service.id === 'elettrici' ? "/img/impianto-elettrico.jpg" : 
                            `/img/services/${service.id}.jpg`} 
                        alt={service.title} 
                        className="img-fluid rounded shadow"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </motion.div>
          ))}
        </Container>
      </section>
      
      {/* CTA Section */}
      <section className="cta-section py-5 bg-light">
        <Container>
          <Row>
            <Col lg={8} className="mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="mb-4">Hai bisogno di un servizio personalizzato?</h2>
                <p className="lead mb-4">
                  Contattaci per discutere delle tue esigenze specifiche. Il nostro team di esperti è pronto ad aiutarti a trovare la soluzione più adatta alle tue necessità.
                </p>
                <Button as={Link} to="/contact" variant="primary" size="lg" className="px-4 py-2">
                  Contattaci ora
                </Button>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services; 