import React from 'react';
import { Carousel } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const HeroCarousel = () => {
  return (
    <section id="hero">
      <Carousel fade interval={5000} controls={true} indicators={true}>
        <Carousel.Item style={{ backgroundImage: 'url(/img/to2.jpg)', height: '80vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="carousel-container d-flex align-items-center justify-content-center h-100">
            <div className="container text-center text-white">
              <motion.h2 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="display-4 fw-bold"
              >
                EL.VI. <span className="text-warning">SERVIZI S.R.L.</span>
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className="my-4 lead"
              >
                Con oltre 30 anni di esperienza nel settore, offriamo soluzioni affidabili che aiutano i nostri clienti a risparmiare energia e ottimizzare i costi. Siamo specializzati nell'installazione e manutenzione di impianti di riscaldamento, climatizzazione e opere edili, e siamo orgogliosi di essere considerati un punto di riferimento nel nostro campo. Con un team di professionisti altamente qualificati, garantiamo un servizio eccellente e di alta qualità a ogni cliente. Scoprite di più sulle nostre soluzioni e su come possiamo aiutarvi.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <Link to="/about" className="btn btn-primary btn-lg px-4 py-2">
                  Leggi di più
                </Link>
              </motion.div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: 'url(/img/to.jpg)', height: '80vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="carousel-container d-flex align-items-center justify-content-center h-100">
            <div className="container text-center text-white">
              <motion.h2 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="display-4 fw-bold"
              >
                La Fiducia
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className="my-4 lead"
              >
                In EL.VI. Servizi s.r.l., capiamo l'importanza di costruire relazioni solide e durature con i nostri partner commerciali. Siamo orgogliosi di offrire un servizio impiantistico eccellente a Torino e dintorni, ma sappiamo che la vera chiave del successo sta nella fiducia che i nostri partner ripongono in noi. Ogni progetto che realizziamo è un'opportunità per dimostrare la nostra competenza e la nostra dedizione alla soddisfazione del cliente. Insieme, possiamo creare soluzioni efficaci e sostenibili che durino nel tempo. La collaborazione di successo con EL.VI. Servizi s.r.l. è alla base di un futuro luminoso per tutti.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <Link to="/about" className="btn btn-primary btn-lg px-4 py-2">
                  Leggi di più
                </Link>
              </motion.div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: 'url(/img/impiantistica1.jpeg)', height: '80vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="carousel-container d-flex align-items-center justify-content-center h-100">
            <div className="container text-center text-white">
              <motion.h2 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="display-4 fw-bold"
              >
                Innovazione e Sostenibilità
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className="my-4 lead"
              >
                EL.VI. Servizi s.r.l. è all'avanguardia nell'adozione di tecnologie innovative e sostenibili per i sistemi di riscaldamento e climatizzazione. Ci impegniamo a ridurre l'impatto ambientale dei nostri progetti, proponendo soluzioni che ottimizzano l'efficienza energetica e riducono i consumi. La nostra esperienza nel settore ci permette di offrire consulenze personalizzate per aiutare i nostri clienti a scegliere le soluzioni più adatte alle loro esigenze, sempre nel rispetto dell'ambiente e con un occhio al futuro.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <Link to="/services" className="btn btn-primary btn-lg px-4 py-2">
                  I nostri servizi
                </Link>
              </motion.div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default HeroCarousel; 