import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const AboutSection = () => {
  return (
    <section id="about" className="about section-padding">
      <Container>
        <div className="section-title">
          <h2>Chi Siamo</h2>
          <p>Scopri la nostra storia e la nostra missione</p>
        </div>

        <Row className="align-items-center">
          <Col lg={6} md={12}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <div className="about-img">
                <img src="/img/to.jpg" className="img-fluid rounded shadow" alt="EL.VI. Servizi Team" />
              </div>
            </motion.div>
          </Col>
          
          <Col lg={6} md={12} className="pt-4 pt-lg-0">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <h3 className="mb-4">La nostra storia di eccellenza nel settore impiantistico</h3>
              <p className="fst-italic mb-4">
                EL.VI. Servizi s.r.l. è un'azienda con oltre 30 anni di esperienza nel settore della manutenzione e dei servizi impiantistici a Torino.
              </p>
              
              <ul className="list-unstyled mb-4">
                <li className="mb-2"><i className="bi bi-check2-circle text-primary me-2"></i> Team di professionisti altamente qualificati</li>
                <li className="mb-2"><i className="bi bi-check2-circle text-primary me-2"></i> Soluzioni personalizzate per ogni cliente</li>
                <li className="mb-2"><i className="bi bi-check2-circle text-primary me-2"></i> Specializzati in impianti termici e teleriscaldamento</li>
                <li className="mb-2"><i className="bi bi-check2-circle text-primary me-2"></i> Attenzione alla sostenibilità ambientale</li>
                <li className="mb-2"><i className="bi bi-check2-circle text-primary me-2"></i> Servizio clienti disponibile 24/7</li>
              </ul>
              
              <p className="mb-4">
                La nostra missione è fornire servizi di alta qualità che soddisfino le esigenze dei nostri clienti, garantendo efficienza, affidabilità e rispetto per l'ambiente. Siamo orgogliosi di essere un punto di riferimento nel settore e di contribuire al benessere delle comunità in cui operiamo.
              </p>
              
              <Button as={Link} to="/about" variant="primary" className="px-4 py-2">
                Scopri di più su di noi
              </Button>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection; 