import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer py-5 bg-dark text-light">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mb-4 mb-lg-0">
            <h4 className="mb-4">EL.VI. SERVIZI S.R.L.</h4>
            <div className="footer-contact">
              <p className="d-flex align-items-center mb-2">
                <FaMapMarkerAlt className="me-2 text-warning" />
                Corso Cosenza 45 C, 10137 Torino
              </p>
              <p className="d-flex align-items-center mb-2">
                <FaPhone className="me-2 text-success" />
                011 317 9913
              </p>
              <p className="d-flex align-items-center mb-2">
                <FaPhone className="me-2 text-danger" />
                Emergenze: 345 372 9649
              </p>
              <p className="d-flex align-items-center mb-2">
                <FaEnvelope className="me-2 text-warning" />
                info@elviservizi.it
              </p>
              <p className="d-flex align-items-center mb-2">
                <FaEnvelope className="me-2 text-success" />
                amministrazione@elviservizi.it
              </p>
              <p className="d-flex align-items-center mb-2">
                <FaEnvelope className="me-2 text-danger" />
                conduzione@elviservizi.it
              </p>
              <p className="d-flex align-items-center">
                <FaEnvelope className="me-2 text-warning" />
                PEC: elviservizi@pec.it
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="mb-4 mb-lg-0">
            <h4 className="mb-4">Link Utili</h4>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/" className="text-light text-decoration-none">Home</Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="text-light text-decoration-none">Chi Siamo</Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="text-light text-decoration-none">Servizi</Link>
              </li>
              <li className="mb-2">
                <Link to="/contact" className="text-light text-decoration-none">Contatti</Link>
              </li>
            </ul>
          </Col>

          <Col lg={4} md={12}>
            <h4 className="mb-4">Orari di Apertura</h4>
            <p className="mb-1">Lunedì - Venerdì: 8:00 - 18:00</p>
            <p className="mb-1">Sabato: Su appuntamento</p>
            <p>Domenica: Chiuso</p>
            <p className="mt-3">Servizio di emergenza disponibile 24/7</p>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row>
          <Col className="text-center">
            <p className="mb-0">&copy; {currentYear} EL.VI. SERVIZI S.R.L. Tutti i diritti riservati.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer; 