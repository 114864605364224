import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';

const Clients = () => {
  // Array di clienti basati sulle immagini presenti
  const clients = [
    {
      id: 1,
      name: 'Comune di Torino',
      logo: '/img/tostemma.png',
      description: 'Partner storico per la manutenzione degli impianti termici degli edifici comunali di Torino.',
      category: 'Enti Pubblici'
    },
    {
      id: 2,
      name: 'Polizia di Stato',
      logo: '/img/polizia logo.jpg',
      description: 'Forniamo servizi di manutenzione e gestione degli impianti termici delle sedi di Polizia, assicurando il comfort e la sicurezza del personale.',
      category: 'Enti Pubblici'
    },
    {
      id: 3,
      name: 'Università di Torino',
      logo: '/img/universita.svg',
      description: 'Siamo responsabili della manutenzione degli impianti di riscaldamento e climatizzazione di diverse sedi universitarie, garantendo ambienti confortevoli per studenti e docenti.',
      category: 'Istruzione'
    },
    {
      id: 4,
      name: 'Iren',
      logo: '/img/logo_Iren_Big.jpg',
      description: 'Gestiamo gli impianti termici e forniamo servizi di manutenzione ordinaria e straordinaria per questo grande gruppo energetico nel nord Italia.',
      category: 'Energia'
    },
    {
      id: 5,
      name: 'GTT - Gruppo Torinese Trasporti',
      logo: '/img/Logo_GTT.png',
      description: 'Ci occupiamo della manutenzione degli impianti di climatizzazione e riscaldamento nelle stazioni e negli uffici GTT, garantendo il comfort dei passeggeri in ogni stagione.',
      category: 'Trasporti'
    },
    {
      id: 6,
      name: 'Juventus',
      logo: '/img/juventus.png',
      description: 'Forniamo servizi di manutenzione degli impianti termici per le strutture della società sportiva, assicurando il massimo comfort per atleti e staff.',
      category: 'Sport'
    },
    {
      id: 7,
      name: 'Italgas',
      logo: '/img/italgas.jpg',
      description: 'Gestiamo gli impianti termici e di climatizzazione in collaborazione con Italgas, garantendo l\'efficienza e la continuità operativa.',
      category: 'Energia'
    },
    {
      id: 8,
      name: 'AMIAT',
      logo: '/img/Amiat_logo.png',
      description: 'Ci occupiamo della manutenzione degli impianti per AMIAT, l\'azienda multiservizi igiene ambientale di Torino.',
      category: 'Servizi Ambientali'
    },
    {
      id: 9,
      name: 'Comune di Collegno',
      logo: '/img/collegno.png',
      description: 'Forniamo servizi di manutenzione degli impianti termici per gli edifici comunali di Collegno.',
      category: 'Enti Pubblici'
    },
    {
      id: 10,
      name: 'ATM',
      logo: '/img/atm.png',
      description: 'Collaboriamo con ATM per la manutenzione degli impianti nelle stazioni e negli uffici.',
      category: 'Trasporti'
    }
  ];

  // Raggruppa i clienti per categoria
  const clientsByCategory = clients.reduce((acc, client) => {
    if (!acc[client.category]) {
      acc[client.category] = [];
    }
    acc[client.category].push(client);
    return acc;
  }, {});

  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - I Nostri Clienti</title>
        <meta 
          name="description" 
          content="Scopri i clienti che si affidano a EL.VI. Servizi per la manutenzione e l'installazione di impianti termici e servizi impiantistici a Torino." 
        />
      </Helmet>
      
      {/* Hero Section */}
      <section className="page-hero py-5 mb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">I Nostri Clienti</h1>
                <p className="lead mb-4">
                  Dal 1980 serviamo con professionalità e competenza i principali enti e aziende del territorio torinese.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Testimonials Section - In fase di sviluppo */}
      {/*
      <section className="testimonials py-5 bg-light">
        <Container>
          <div className="section-title text-center mb-5">
            <h2>Cosa Dicono di Noi</h2>
            <p>Le opinioni dei nostri clienti</p>
          </div>
          
          <Row>
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Card className="h-100 border-0 shadow-sm p-4">
                  <Card.Body>
                    <div className="d-flex mb-4">
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning"></i>
                    </div>
                    <Card.Text className="mb-4">
                      "EL.VI. Servizi ha dimostrato grande professionalità nella gestione dei nostri impianti termici. Il loro team è sempre disponibile e interviene tempestivamente in caso di necessità. Consigliamo vivamente i loro servizi."
                    </Card.Text>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="mb-0">Marco Bianchi</h5>
                        <p className="text-muted mb-0">Amministratore di Condominio</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Card className="h-100 border-0 shadow-sm p-4">
                  <Card.Body>
                    <div className="d-flex mb-4">
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning"></i>
                    </div>
                    <Card.Text className="mb-4">
                      "Collaboriamo con EL.VI. Servizi da diversi anni per la manutenzione dei nostri impianti. La loro competenza e affidabilità ci hanno permesso di ottimizzare i consumi energetici e ridurre i costi di gestione."
                    </Card.Text>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="mb-0">Laura Rossi</h5>
                        <p className="text-muted mb-0">Direttore Facility Management</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Card className="h-100 border-0 shadow-sm p-4">
                  <Card.Body>
                    <div className="d-flex mb-4">
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-fill text-warning me-1"></i>
                      <i className="bi bi-star-half text-warning"></i>
                    </div>
                    <Card.Text className="mb-4">
                      "Abbiamo affidato a EL.VI. Servizi la ristrutturazione completa del nostro impianto di riscaldamento. Il risultato è stato eccellente, con un notevole miglioramento del comfort e una significativa riduzione dei consumi."
                    </Card.Text>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="mb-0">Giovanni Verdi</h5>
                        <p className="text-muted mb-0">Proprietario Hotel</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      */}
      
      {/* Clients by Category Section */}
      <section className="clients-list py-5">
        <Container>
          <div className="section-title text-center mb-5">
            <h2>I Nostri Clienti per Settore</h2>
            <p>Serviamo clienti in diversi settori con soluzioni personalizzate</p>
          </div>
          
          {Object.entries(clientsByCategory).map(([category, categoryClients], index) => (
            <div key={category} className="mb-5">
              <motion.h3
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="mb-4 border-bottom pb-2"
              >
                {category}
              </motion.h3>
              
              <Row>
                {categoryClients.map((client, clientIndex) => (
                  <Col lg={6} className="mb-4" key={client.id}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: clientIndex * 0.1 }}
                    >
                      <Card className="h-100 border-0 shadow-sm">
                        <Card.Body className="p-4">
                          <Row className="align-items-center">
                            <Col md={4} className="text-center mb-3 mb-md-0">
                              <img 
                                src={client.logo} 
                                alt={client.name} 
                                className="img-fluid" 
                                style={{ maxHeight: '100px' }}
                              />
                            </Col>
                            <Col md={8}>
                              <Card.Title as="h4">{client.name}</Card.Title>
                              <Card.Text className="text-muted">
                                {client.description}
                              </Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </motion.div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Container>
      </section>
    </>
  );
};

export default Clients; 