import React from 'react';
import { Helmet } from 'react-helmet';
import HeroCarousel from '../components/HeroCarousel';
import AboutSection from '../components/AboutSection';
import ServicesSection from '../components/ServicesSection';
import ClientsCarousel from '../components/ClientsCarousel';
import ContactSection from '../components/ContactSection';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - Home</title>
        <meta 
          name="description" 
          content="EL.VI. Servizi è un'azienda leader nel settore della manutenzione e dei servizi impiantistici a Torino. Con un team altamente qualificato e una lunga esperienza nel settore, siamo in grado di offrire soluzioni affidabili e convenienti ai nostri clienti." 
        />
      </Helmet>
      
      <HeroCarousel />
      
      <main>
        <AboutSection />
        <ServicesSection />
        <ClientsCarousel />
        <ContactSection />
      </main>
    </>
  );
};

export default Home; 