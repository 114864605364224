import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { motion } from 'framer-motion';

const ClientsCarousel = () => {
  const clients = [
    {
      id: 1,
      name: "Comune di Torino",
      logo: "/img/tostemma.png",
      description: "Servizi di manutenzione impianti termici"
    },
    {
      id: 2,
      name: "Polizia di Stato",
      logo: "/img/polizia logo.jpg",
      description: "Manutenzione e gestione impianti"
    },
    {
      id: 3,
      name: "Università di Torino",
      logo: "/img/universita.svg",
      description: "Servizi di terzo responsabile"
    },
    {
      id: 4,
      name: "Iren",
      logo: "/img/logo_iren.png",
      description: "Partnership per servizi energetici"
    },
    {
      id: 5,
      name: "GTT",
      logo: "/img/Logo_GTT.png",
      description: "Manutenzione impianti termici"
    },
    {
      id: 6,
      name: "Juventus",
      logo: "/img/juventus.png",
      description: "Gestione impianti sportivi"
    },
    {
      id: 7,
      name: "Italgas",
      logo: "/img/italgas.jpg",
      description: "Collaborazione tecnica"
    },
    {
      id: 8,
      name: "AMIAT",
      logo: "/img/Amiat_logo.png",
      description: "Servizi di manutenzione"
    },
    {
      id: 9,
      name: "Comune di Collegno",
      logo: "/img/collegno.png",
      description: "Manutenzione impianti pubblici"
    }
  ];

  // Dividiamo i clienti in gruppi di 3 per il carousel
  const clientGroups = [];
  for (let i = 0; i < clients.length; i += 3) {
    clientGroups.push(clients.slice(i, i + 3));
  }

  return (
    <section className="clients-carousel-section py-5 bg-light">
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="section-title text-center mb-5"
        >
          <h2>I Nostri Clienti</h2>
          <p>Collaboriamo con aziende e istituzioni leader nel territorio</p>
        </motion.div>
        
        <Carousel 
          indicators={true}
          controls={true}
          interval={3000}
          className="clients-carousel"
        >
          {clientGroups.map((group, groupIndex) => (
            <Carousel.Item key={groupIndex}>
              <Row className="justify-content-center">
                {group.map(client => (
                  <Col key={client.id} md={4} className="mb-4">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                      className="client-card text-center p-4 bg-white shadow-sm rounded h-100"
                    >
                      <div className="client-logo mb-3 d-flex justify-content-center align-items-center" style={{ height: "150px" }}>
                        <img
                          src={client.logo}
                          alt={client.name}
                          className="img-fluid"
                          style={{
                            maxHeight: "120px",
                            objectFit: "contain"
                          }}
                        />
                      </div>
                      <h5>{client.name}</h5>
                      <p className="text-muted small">{client.description}</p>
                    </motion.div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default ClientsCarousel; 