import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix per le icone di Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null }
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setStatus(prevStatus => ({ ...prevStatus, submitting: true }));
    
    try {
      // In un'implementazione reale, qui invieresti i dati a un endpoint API
      // Per ora simuliamo una richiesta di successo
      // await axios.post('/api/contact', formData);
      
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: 'Messaggio inviato con successo! Ti contatteremo presto.' }
      });
      
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      setTimeout(() => {
        setStatus(prevStatus => ({ ...prevStatus, submitted: false, info: { error: false, msg: null } }));
      }, 5000);
      
    } catch (error) {
      setStatus({
        submitted: false,
        submitting: false,
        info: { error: true, msg: 'Si è verificato un errore. Riprova più tardi.' }
      });
    }
  };

  // Coordinate di EL.VI. Servizi (Corso Cosenza 45, Torino)
  const position = [45.038631, 7.64256]; // Torino, Corso Cosenza 45 C

  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - Contattaci</title>
        <meta 
          name="description" 
          content="Contatta EL.VI. Servizi per informazioni sui nostri servizi di manutenzione e installazione impianti a Torino. Siamo a tua disposizione per preventivi e consulenze." 
        />
      </Helmet>
      
      {/* Hero Section */}
      <section className="page-hero py-5 mb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">Contattaci</h1>
                <p className="lead mb-4">
                  Siamo a tua disposizione per qualsiasi informazione o richiesta. Compila il form o contattaci direttamente.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Contact Info Section */}
      <section className="contact-info py-5">
        <Container>
          <Row>
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <Card.Body>
                    <div className="icon-box mb-4">
                      <FaMapMarkerAlt className="text-warning" size={48} />
                    </div>
                    <Card.Title as="h4" className="mb-3">Sede</Card.Title>
                    <Card.Text>
                      Corso Cosenza 45 C<br />
                      10137 Torino (TO)<br />
                      Italia
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <Card.Body>
                    <div className="icon-box mb-4">
                      <FaPhone className="text-success" size={48} />
                    </div>
                    <Card.Title as="h4" className="mb-3">Telefono</Card.Title>
                    <Card.Text>
                      <a href="tel:+390113179913" className="text-decoration-none">011 317 9913</a><br />
                      <a href="tel:+393453729649" className="text-decoration-none">345 372 9649</a> (Emergenze)
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={4} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <Card.Body>
                    <div className="icon-box mb-4">
                      <FaEnvelope className="text-danger" size={48} />
                    </div>
                    <Card.Title as="h4" className="mb-3">Email</Card.Title>
                    <Card.Text>
                      <a href="mailto:info@elviservizi.it" className="text-decoration-none">info@elviservizi.it</a><br />
                      <a href="mailto:amministrazione@elviservizi.it" className="text-decoration-none">amministrazione@elviservizi.it</a><br />
                      <a href="mailto:conduzione@elviservizi.it" className="text-decoration-none">conduzione@elviservizi.it</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={4} md={6} className="mb-4 mx-auto">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <Card.Body>
                    <div className="icon-box mb-4">
                      <FaClock className="text-warning" size={48} />
                    </div>
                    <Card.Title as="h4" className="mb-3">Orari</Card.Title>
                    <Card.Text>
                      Lunedì - Venerdì: 8:30 - 18:00<br />
                      Sabato: 9:00 - 13:00<br />
                      Domenica: Chiuso<br />
                      <span className="text-danger">Servizio emergenze 24/7</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Contact Form and Map Section */}
      <section className="contact-form-map py-5 bg-light">
        <Container>
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <div className="section-title mb-4">
                  <h2>Inviaci un messaggio</h2>
                  <p>Compila il form e ti risponderemo al più presto</p>
                </div>
                
                {status.info.msg && (
                  <Alert variant={status.info.error ? 'danger' : 'success'} className="mb-4">
                    {status.info.msg}
                  </Alert>
                )}
                
                <Form onSubmit={handleSubmit} className="p-4 bg-white rounded shadow-sm" action="https://formsubmit.co/info@elviservizi.it" method="POST">
                  <input type="hidden" name="_subject" value="Nuovo messaggio dal sito web EL.VI. SERVIZI" />
                  <input type="hidden" name="_captcha" value="false" />
                  <input type="hidden" name="_next" value="https://elviservizi.it/grazie" />
                  <input type="text" name="_honey" style={{ display: 'none' }} />
                  
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Il tuo nome</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="name" 
                          value={formData.name}
                          onChange={handleChange}
                          required 
                        />
                      </Form.Group>
                    </Col>
                    
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>La tua email</Form.Label>
                        <Form.Control 
                          type="email" 
                          name="email" 
                          value={formData.email}
                          onChange={handleChange}
                          required 
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  
                  <Form.Group className="mb-3">
                    <Form.Label>Oggetto</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="subject" 
                      value={formData.subject}
                      onChange={handleChange}
                      required 
                    />
                  </Form.Group>
                  
                  <Form.Group className="mb-4">
                    <Form.Label>Messaggio</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      name="message" 
                      rows={5} 
                      value={formData.message}
                      onChange={handleChange}
                      required 
                    />
                  </Form.Group>
                  
                  <div className="text-center">
                    <Button 
                      type="submit" 
                      variant="primary" 
                      size="lg"
                      disabled={status.submitting}
                      className="px-4"
                    >
                      {status.submitting ? 'Invio in corso...' : 'Invia Messaggio'}
                    </Button>
                  </div>
                </Form>
              </motion.div>
            </Col>
            
            <Col lg={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <div className="section-title mb-4">
                  <h2>Dove siamo</h2>
                  <p>Vieni a trovarci nella nostra sede</p>
                </div>
                
                <div className="map-container rounded shadow-sm overflow-hidden" style={{ height: '450px' }}>
                  <MapContainer center={position} zoom={15} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={position}>
                      <Popup>
                        <strong>EL.VI. SERVIZI S.R.L.</strong><br />
                        Corso Cosenza 45 C<br />
                        10137 Torino (TO)
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact; 