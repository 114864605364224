import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Componenti Layout
import Header from './components/Header';
import Footer from './components/Footer';

// Pagine
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Contact from './pages/Contact';
import Tos from './pages/Tos';

function App() {
  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L.</title>
        <meta name="description" content="EL.VI. Servizi è un'azienda leader nel settore della manutenzione e dei servizi impiantistici a Torino." />
      </Helmet>
      
      <Header />
      
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tos" element={<Tos />} />
        </Routes>
      </main>
      
      <Footer />
    </>
  );
}

export default App; 