import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ServiceCard from './ServiceCard';

const ServicesSection = () => {
  const services = [
    {
      icon: 'building-gear',
      title: 'Manutenzione Stabili',
      description: 'Servizi completi di manutenzione ordinaria e straordinaria per edifici residenziali e commerciali, garantendo efficienza e sicurezza.',
      link: '/services#manutenzione'
    },
    {
      icon: 'thermometer-half',
      title: 'Impianti Termici',
      description: 'Installazione, manutenzione e riparazione di impianti di riscaldamento e climatizzazione, con soluzioni personalizzate per ogni esigenza.',
      link: '/services#termici'
    },
    {
      icon: 'clipboard-check',
      title: 'Terzo Responsabile',
      description: 'Servizio di terzo responsabile per la gestione degli impianti termici, garantendo il rispetto delle normative vigenti e l\'efficienza energetica.',
      link: '/services#responsabile'
    },
    {
      icon: 'file-earmark-text',
      title: 'Pratiche Edili',
      description: 'Gestione completa delle pratiche edilizie e amministrative, dalla progettazione alla realizzazione, con un team di professionisti esperti.',
      link: '/services#pratiche'
    },
    {
      icon: 'house-gear',
      title: 'Ristrutturazioni',
      description: 'Servizi di ristrutturazione completa di edifici residenziali e commerciali, con attenzione ai dettagli e utilizzo di materiali di qualità.',
      link: '/services#ristrutturazioni'
    },
    {
      icon: 'lightning-charge',
      title: 'Impianti Elettrici',
      description: 'Progettazione, installazione e manutenzione di impianti elettrici civili e industriali, con soluzioni innovative e risparmio energetico.',
      link: '/services#elettrici'
    }
  ];

  return (
    <section id="services" className="services section-padding bg-light">
      <Container>
        <div className="section-title">
          <h2>I Nostri Servizi</h2>
          <p>Soluzioni complete per le tue esigenze impiantistiche</p>
        </div>

        <Row>
          {services.map((service, index) => (
            <Col lg={4} md={6} className="mb-4" key={index}>
              <ServiceCard 
                icon={service.icon}
                title={service.title}
                description={service.description}
                link={service.link}
                index={index}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection; 