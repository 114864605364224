import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/variables.scss';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const buttonVariants = {
    hover: { 
      scale: 1.05,
      boxShadow: "0px 5px 15px rgba(227, 30, 36, 0.3)",
      transition: { 
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    },
    tap: { 
      scale: 0.95 
    }
  };

  return (
    <header id="header" className={`fixed-top ${scrolled ? 'header-scrolled' : ''}`}>
      <Navbar 
        bg="light" 
        expand="lg" 
        className={`navbar-light py-3 ${scrolled ? 'shadow-sm' : ''}`}
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Container className="d-flex align-items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
              <img 
                src="/img/Logo.jpg" 
                alt="EL.VI. SERVIZI" 
                className="img-fluid me-2" 
                style={{ maxHeight: '50px' }} 
              />
              <motion.h1 
                className="logo m-0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                EL.VI. SERVIZI s.r.l.
              </motion.h1>
            </Navbar.Brand>
          </motion.div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <AnimatePresence>
                <motion.div
                  variants={navVariants}
                  initial="hidden"
                  animate="visible"
                  className="d-flex align-items-center"
                >
                  <Nav.Link 
                    as={NavLink} 
                    to="/" 
                    className={({ isActive }) => isActive ? 'active' : ''}
                    onClick={() => setExpanded(false)}
                  >
                    Home
                  </Nav.Link>
                  
                  <NavDropdown title="Su di noi" id="basic-nav-dropdown">
                    <NavDropdown.Item 
                      as={NavLink} 
                      to="/about"
                      onClick={() => setExpanded(false)}
                    >
                      Su di noi
                    </NavDropdown.Item>
                    <NavDropdown.Item 
                      as={NavLink} 
                      to="/contact"
                      onClick={() => setExpanded(false)}
                    >
                      Contattaci
                    </NavDropdown.Item>
                  </NavDropdown>
                  
                  <Nav.Link 
                    as={NavLink} 
                    to="/services" 
                    className={({ isActive }) => isActive ? 'active' : ''}
                    onClick={() => setExpanded(false)}
                  >
                    Servizi
                  </Nav.Link>
                  
                  <Nav.Link 
                    as={NavLink} 
                    to="/clients" 
                    className={({ isActive }) => isActive ? 'active' : ''}
                    onClick={() => setExpanded(false)}
                  >
                    Clienti
                  </Nav.Link>
                  
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <Nav.Link 
                      as={NavLink} 
                      to="/contact" 
                      className="btn btn-primary text-white ms-3 px-4"
                      onClick={() => setExpanded(false)}
                    >
                      Contattaci
                    </Nav.Link>
                  </motion.div>
                </motion.div>
              </AnimatePresence>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header; 