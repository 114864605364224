import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null }
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <section className="contact-section py-5">
      <Container>
        <div className="section-title text-center mb-5">
          <h2>Contattaci</h2>
          <p>Siamo qui per aiutarti</p>
        </div>

        <Row>
          <Col lg={6} className="mb-4">
            <div className="contact-info p-4 bg-light rounded">
              <div className="d-flex align-items-center mb-4">
                <FaMapMarkerAlt className="text-warning me-3" size={24} />
                <div>
                  <h5 className="mb-1">Indirizzo</h5>
                  <p className="mb-0">Corso Cosenza 45 C, 10137 Torino</p>
                </div>
              </div>

              <div className="d-flex align-items-center mb-4">
                <FaPhone className="text-success me-3" size={24} />
                <div>
                  <h5 className="mb-1">Telefono</h5>
                  <p className="mb-0">011 317 9913</p>
                  <p className="mb-0">Emergenze: 345 372 9649</p>
                </div>
              </div>

              <div className="d-flex align-items-center mb-4">
                <FaEnvelope className="text-danger me-3" size={24} />
                <div>
                  <h5 className="mb-1">Email</h5>
                  <p className="mb-0">info@elviservizi.it (Informazioni generali)</p>
                  <p className="mb-0">amministrazione@elviservizi.it (Amministrazione)</p>
                  <p className="mb-0">conduzione@elviservizi.it (Appalti e Servizi)</p>
                  <p className="mb-0">PEC: elviservizi@pec.it</p>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="contact-map">
              <iframe
                src="https://www.openstreetmap.org/export/embed.html?bbox=7.6394%2C45.0306%2C7.6434%2C45.0326&amp;layer=mapnik&amp;marker=45.0316%2C7.6414"
                style={{ border: 0, width: '100%', height: '400px' }}
                allowFullScreen=""
                loading="lazy"
                title="Mappa EL.VI. Servizi"
              ></iframe>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={8} className="mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="p-4 bg-white rounded shadow-sm"
            >
              {status.info.msg && (
                <Alert variant={status.info.error ? 'danger' : 'success'}>
                  {status.info.msg}
                </Alert>
              )}
              
              {/* Form semplificato per il primo utilizzo di FormSubmit */}
              <Form action="https://formsubmit.co/info@elviservizi.it" method="POST">
                <input type="hidden" name="_subject" value="Nuovo messaggio dal sito web EL.VI. Servizi" />
                <input type="hidden" name="_template" value="table" />
                
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Il tuo nome</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="name" 
                        required 
                      />
                    </Form.Group>
                  </Col>
                  
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>La tua email</Form.Label>
                      <Form.Control 
                        type="email" 
                        name="email" 
                        required 
                      />
                    </Form.Group>
                  </Col>
                </Row>
                
                <Form.Group className="mb-3">
                  <Form.Label>Oggetto</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="subject" 
                    required 
                  />
                </Form.Group>
                
                <Form.Group className="mb-4">
                  <Form.Label>Messaggio</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    name="message" 
                    rows={5} 
                    required 
                  />
                </Form.Group>
                
                <div className="text-center">
                  <Button 
                    type="submit" 
                    variant="primary" 
                    size="lg"
                  >
                    Invia Messaggio
                  </Button>
                </div>
              </Form>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection; 