import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';

const Tos = () => {
  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - Termini di Servizio</title>
        <meta 
          name="description" 
          content="Termini e condizioni di servizio di EL.VI. Servizi S.R.L. per i servizi di manutenzione e installazione impianti a Torino." 
        />
      </Helmet>
      
      {/* Hero Section */}
      <section className="page-hero bg-primary text-white py-5 mb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">Termini di Servizio</h1>
                <p className="lead mb-4">
                  Informazioni legali e condizioni di utilizzo dei nostri servizi
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Terms Section */}
      <section className="terms-section py-5">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">1. Introduzione</h2>
                    <p>
                      Benvenuti sul sito web di EL.VI. SERVIZI S.R.L. ("noi", "nostro", o "EL.VI. Servizi"). Utilizzando il nostro sito web e i nostri servizi, accetti di essere vincolato dai seguenti termini e condizioni ("Termini di Servizio"). Ti preghiamo di leggerli attentamente.
                    </p>
                    <p>
                      Questi Termini di Servizio si applicano a tutti gli utenti del sito, compresi, senza limitazione, gli utenti che sono browser, clienti, fornitori, e/o contributori di contenuti.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">2. Servizi Offerti</h2>
                    <p>
                      EL.VI. Servizi offre servizi di manutenzione e installazione di impianti termici, elettrici e idraulici, nonché servizi di ristrutturazione e pratiche edili. I dettagli specifici dei servizi sono descritti nelle relative sezioni del sito web.
                    </p>
                    <p>
                      Ci riserviamo il diritto di modificare, sospendere o interrompere, temporaneamente o permanentemente, il sito o qualsiasi servizio a cui si accede attraverso il sito, con o senza preavviso e senza responsabilità nei tuoi confronti.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">3. Preventivi e Contratti</h2>
                    <p>
                      I preventivi forniti da EL.VI. Servizi sono validi per 30 giorni dalla data di emissione, salvo diversamente specificato. I preventivi sono basati sulle informazioni fornite dal cliente e possono essere soggetti a modifiche dopo un sopralluogo o in caso di variazioni delle condizioni iniziali.
                    </p>
                    <p>
                      Un contratto tra EL.VI. Servizi e il cliente si considera concluso solo dopo l'accettazione scritta del preventivo da parte del cliente e la conferma da parte di EL.VI. Servizi.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">4. Pagamenti</h2>
                    <p>
                      Le modalità di pagamento accettate sono specificate nel preventivo o nel contratto. In generale, per lavori di importo significativo, è richiesto un acconto all'accettazione del preventivo, pagamenti intermedi in base all'avanzamento dei lavori e il saldo alla conclusione dei lavori.
                    </p>
                    <p>
                      In caso di ritardo nei pagamenti, ci riserviamo il diritto di applicare interessi di mora secondo le normative vigenti e di sospendere l'esecuzione dei lavori fino alla regolarizzazione dei pagamenti.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">5. Garanzie</h2>
                    <p>
                      EL.VI. Servizi garantisce che tutti i servizi saranno eseguiti a regola d'arte e in conformità con le normative vigenti. I materiali e le apparecchiature installate sono coperti dalla garanzia del produttore.
                    </p>
                    <p>
                      Per i lavori di installazione e ristrutturazione, EL.VI. Servizi offre una garanzia di 24 mesi dalla data di conclusione dei lavori, in conformità con la normativa vigente. La garanzia copre difetti di installazione e non si applica in caso di uso improprio, mancata manutenzione o modifiche effettuate da terzi.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">6. Responsabilità</h2>
                    <p>
                      EL.VI. Servizi è responsabile per danni diretti causati da negligenza o inadempimento contrattuale. Non siamo responsabili per danni indiretti o consequenziali, inclusi, senza limitazione, perdita di profitto, interruzione di attività o perdita di dati.
                    </p>
                    <p>
                      La nostra responsabilità è limitata all'importo pagato dal cliente per i servizi in questione, salvo nei casi in cui la legge preveda diversamente.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">7. Privacy</h2>
                    <p>
                      EL.VI. Servizi si impegna a proteggere la privacy dei propri clienti. Le informazioni personali raccolte saranno utilizzate solo per fornire i servizi richiesti e per comunicazioni relative ai servizi. Per ulteriori dettagli, consulta la nostra Informativa sulla Privacy.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">8. Proprietà Intellettuale</h2>
                    <p>
                      Tutti i contenuti presenti sul sito web di EL.VI. Servizi, inclusi, senza limitazione, testi, grafica, loghi, icone, immagini, clip audio, download digitali e software, sono di proprietà di EL.VI. Servizi o dei suoi fornitori di contenuti e sono protetti dalle leggi sul copyright e sulla proprietà intellettuale.
                    </p>
                    <p>
                      È vietata la riproduzione, distribuzione, modifica o utilizzo dei contenuti del sito senza l'autorizzazione scritta di EL.VI. Servizi.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">9. Legge Applicabile e Foro Competente</h2>
                    <p>
                      Questi Termini di Servizio sono regolati e interpretati in conformità con le leggi italiane. Qualsiasi controversia derivante da o relativa a questi Termini di Servizio sarà soggetta alla giurisdizione esclusiva del Foro di Torino, Italia.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4 mb-5">
                  <Card.Body>
                    <h2 className="mb-4">10. Modifiche ai Termini di Servizio</h2>
                    <p>
                      Ci riserviamo il diritto di modificare questi Termini di Servizio in qualsiasi momento. Le modifiche saranno effettive immediatamente dopo la pubblicazione sul sito web. L'uso continuato del sito dopo tali modifiche costituisce l'accettazione dei nuovi Termini di Servizio.
                    </p>
                    <p>
                      È responsabilità dell'utente controllare periodicamente eventuali modifiche a questi Termini di Servizio.
                    </p>
                  </Card.Body>
                </Card>
                
                <Card className="border-0 shadow-sm p-4">
                  <Card.Body>
                    <h2 className="mb-4">11. Contatti</h2>
                    <p>
                      Per qualsiasi domanda riguardante questi Termini di Servizio, contattaci a:
                    </p>
                    <p>
                      EL.VI. SERVIZI S.R.L.<br />
                      Via Sansovino 243/35 bis<br />
                      10151 Torino (TO)<br />
                      Email: info@elviservizi.it<br />
                      Telefono: +39 011 739 0606
                    </p>
                    <p className="mb-0">
                      Ultimo aggiornamento: {new Date().toLocaleDateString('it-IT')}
                    </p>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tos; 