import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const ServiceCard = ({ icon, title, description, link, index }) => {
  // Determina il testo del pulsante in base al titolo del servizio
  let buttonText = "Scopri di più";
  
  if (title === "Manutenzione Stabili") {
    buttonText = "Richiedi un intervento";
  } else if (title === "Impianti Termici") {
    buttonText = "Preventivo gratuito";
  } else if (title === "Terzo Responsabile") {
    buttonText = "Richiedi informazioni";
  } else if (title === "Pratiche Edili") {
    buttonText = "Consulenza tecnica";
  } else if (title === "Ristrutturazioni") {
    buttonText = "Preventivo gratuito";
  } else if (title === "Impianti Elettrici") {
    buttonText = "Richiedi sopralluogo";
  }

  // Determina la classe dell'icona
  const iconColorClass = `service-icon service-icon-${(index % 3) + 1}`;

  return (
    <motion.div
      whileHover={{ y: -10, transition: { duration: 0.3 } }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <Card className="h-100 border-0 shadow-sm">
        <Card.Body className="text-center p-4">
          <div className={`icon-box mb-4 ${iconColorClass}`}>
            <i className={`bi bi-${icon}`} style={{ fontSize: '3rem' }}></i>
          </div>
          <Card.Title as="h4" className="mb-3">{title}</Card.Title>
          <Card.Text className="text-muted mb-4">
            {description}
          </Card.Text>
          <Link to={link} className="btn btn-outline-primary">
            {buttonText}
          </Link>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

export default ServiceCard; 