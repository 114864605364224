import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FaUsers, FaAward, FaHandshake, FaLeaf } from 'react-icons/fa';

const About = () => {
  return (
    <>
      <Helmet>
        <title>EL.VI. SERVIZI S.R.L. - Chi Siamo</title>
        <meta 
          name="description" 
          content="Scopri la storia e i valori di EL.VI. Servizi, azienda leader nel settore della manutenzione e dei servizi impiantistici a Torino con oltre 30 anni di esperienza." 
        />
      </Helmet>
      
      {/* Hero Section */}
      <section className="page-hero bg-primary text-white py-5 mb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">Chi Siamo</h1>
                <p className="lead mb-4">
                  EL.VI. Servizi è un'azienda con oltre 30 anni di esperienza nel settore della manutenzione e dei servizi impiantistici a Torino.
                </p>
              </motion.div>
            </Col>
            <Col lg={6} className="d-none d-lg-block">
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
                src="/img/about-hero.jpg"
                alt="EL.VI. Servizi Team"
                className="img-fluid rounded shadow"
              />
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Storia Section */}
      <section className="about-history py-5">
        <Container>
          <div className="section-title text-center mb-5">
            <h2>La Nostra Storia</h2>
            <p>Un percorso di crescita e innovazione</p>
          </div>
          
          <Row className="align-items-center">
            <Col lg={6} className="mb-4 mb-lg-0">
              <motion.img
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                src="/img/history.jpg"
                alt="Storia di EL.VI. Servizi"
                className="img-fluid rounded shadow"
              />
            </Col>
            
            <Col lg={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <h3 className="mb-4">Dal 1990 al servizio dei clienti</h3>
                <p className="mb-4">
                  EL.VI. Servizi nasce nel 1990 dall'idea di un gruppo di professionisti con esperienza nel settore impiantistico. Da allora, l'azienda è cresciuta costantemente, ampliando la propria offerta di servizi e consolidando la propria presenza sul territorio torinese.
                </p>
                <p className="mb-4">
                  Nel corso degli anni, abbiamo investito in formazione e tecnologie all'avanguardia per offrire ai nostri clienti soluzioni sempre più efficienti e sostenibili. La nostra esperienza ci ha permesso di affrontare con successo progetti di ogni dimensione, dai piccoli interventi di manutenzione alle grandi installazioni impiantistiche.
                </p>
                <p>
                  Oggi, EL.VI. Servizi è un punto di riferimento nel settore, grazie alla professionalità del proprio team e alla qualità dei servizi offerti. Continuiamo a guardare al futuro con entusiasmo, pronti ad affrontare nuove sfide e a soddisfare le esigenze dei nostri clienti.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Valori Section */}
      <section className="about-values py-5 bg-light">
        <Container>
          <div className="section-title text-center mb-5">
            <h2>I Nostri Valori</h2>
            <p>Principi che guidano il nostro lavoro quotidiano</p>
          </div>
          
          <Row>
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <div className="icon-box mb-4">
                    <FaUsers className="text-warning" size={48} />
                  </div>
                  <Card.Body>
                    <Card.Title as="h4" className="mb-3">Professionalità</Card.Title>
                    <Card.Text>
                      Il nostro team è composto da professionisti qualificati e costantemente aggiornati sulle ultime tecnologie e normative del settore.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <div className="icon-box mb-4">
                    <FaAward className="text-warning" size={48} />
                  </div>
                  <Card.Body>
                    <Card.Title as="h4" className="mb-3">Qualità</Card.Title>
                    <Card.Text>
                      Ci impegniamo a offrire servizi di alta qualità, utilizzando materiali e tecnologie all'avanguardia per garantire risultati duraturi.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <div className="icon-box mb-4">
                    <FaHandshake className="text-warning" size={48} />
                  </div>
                  <Card.Body>
                    <Card.Title as="h4" className="mb-3">Affidabilità</Card.Title>
                    <Card.Text>
                      Rispettiamo gli impegni presi con i nostri clienti, garantendo puntualità, trasparenza e soluzioni personalizzate per ogni esigenza.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Card className="h-100 border-0 shadow-sm text-center p-4">
                  <div className="icon-box mb-4">
                    <FaLeaf className="text-warning" size={48} />
                  </div>
                  <Card.Body>
                    <Card.Title as="h4" className="mb-3">Sostenibilità</Card.Title>
                    <Card.Text>
                      Promuoviamo soluzioni eco-sostenibili che riducono l'impatto ambientale e ottimizzano l'efficienza energetica degli impianti.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* Team Section - In fase di sviluppo */}
      {/* 
      <section className="about-team py-5">
        <Container>
          <div className="section-title text-center mb-5">
            <h2>Il Nostro Team</h2>
            <p>Professionisti al tuo servizio</p>
          </div>
          
          <Row>
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                whileHover={{ y: -10, transition: { duration: 0.3 } }}
              >
                <Card className="border-0 shadow-sm text-center">
                  <Card.Img variant="top" src="/img/team/team-1.jpg" />
                  <Card.Body className="p-4">
                    <Card.Title as="h4">Mario Rossi</Card.Title>
                    <Card.Subtitle className="mb-3 text-muted">CEO & Fondatore</Card.Subtitle>
                    <Card.Text>
                      Con oltre 30 anni di esperienza nel settore, guida l'azienda con passione e visione.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                whileHover={{ y: -10, transition: { duration: 0.3 } }}
              >
                <Card className="border-0 shadow-sm text-center">
                  <Card.Img variant="top" src="/img/team/team-2.jpg" />
                  <Card.Body className="p-4">
                    <Card.Title as="h4">Laura Bianchi</Card.Title>
                    <Card.Subtitle className="mb-3 text-muted">Direttore Tecnico</Card.Subtitle>
                    <Card.Text>
                      Ingegnere con specializzazione in impianti termici e grande esperienza sul campo.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ y: -10, transition: { duration: 0.3 } }}
              >
                <Card className="border-0 shadow-sm text-center">
                  <Card.Img variant="top" src="/img/team/team-3.jpg" />
                  <Card.Body className="p-4">
                    <Card.Title as="h4">Giovanni Verdi</Card.Title>
                    <Card.Subtitle className="mb-3 text-muted">Responsabile Tecnico</Card.Subtitle>
                    <Card.Text>
                      Esperto in impianti termici e di climatizzazione, supervisiona i progetti più complessi.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            
            <Col lg={3} md={6} className="mb-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
                whileHover={{ y: -10, transition: { duration: 0.3 } }}
              >
                <Card className="border-0 shadow-sm text-center">
                  <Card.Img variant="top" src="/img/team/team-4.jpg" />
                  <Card.Body className="p-4">
                    <Card.Title as="h4">Anna Neri</Card.Title>
                    <Card.Subtitle className="mb-3 text-muted">Responsabile Amministrativo</Card.Subtitle>
                    <Card.Text>
                      Gestisce con efficienza l'amministrazione e la contabilità dell'azienda.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      */}
    </>
  );
};

export default About; 